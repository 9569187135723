import React from 'react';
import Image from 'next/image';
import Fade from '../animation/Fade';

const Banner = () => {
  return (
    <Fade
      as='div'
      duration={0.25}
      className='aspect-1 bg-secondary-light relative overflow-hidden rounded-lg md:aspect-[2/1] lg:aspect-[3/1]'
    >
      <Image
        src='/images/banner/home_hero_large.png'
        alt='ittihad-banner'
        className='inset-0 hidden object-left lg:block'
        fill
        sizes='2100px'
        priority
      />
      <Image
        src='/images/banner/home_hero_mid.png'
        alt='ittihad-banner'
        className='inset-0 hidden object-left md:block lg:hidden'
        fill
        sizes='1200px'
        priority
      />
      <Image
        src='/images/banner/home_hero_small.png'
        alt='ittihad-banner'
        className='inset-0 block object-left md:hidden'
        fill
        sizes='500px'
        priority
      />
    </Fade>
  );
};

export default Banner;
