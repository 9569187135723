import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { useForm } from 'react-hook-form';
import { emailPattern } from '@/src/constants/pattrens';
import { useSubscribe } from '@/api/newsletter/subscribe';
import Reveal from '../animation/Reveal';
import MainButton from '../buttons/MainButton';
import Input from '../inputs/Input';

const NewsLetterSection = () => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<{ email: string }>();
  const { mutateAsync, isPending } = useSubscribe();
  const email = watch('email') || '';

  const onSubmit = async (data: { email: string }) => {
    try {
      await mutateAsync({ email: data.email });
      setSuccess(true);
    } catch (error) {
      //
    }
  };

  return (
    <Reveal
      as='section'
      direction='bottom'
      className='text-text-dark container mb-12 overflow-hidden px-4'
    >
      <div className='relative flex flex-col-reverse rounded-lg bg-gray-100 md:flex-row'>
        <div className='z-10 grow p-4 md:px-6'>
          <p className='text-2xl font-bold uppercase'>{t('common:newsletter.title')}</p>
          <p className='text-lg'>{t('common:newsletter.description')}</p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='mt-4 flex flex-col items-start gap-1 sm:flex-row'
          >
            <fieldset className='w-full grow md:max-w-md'>
              <Input
                className='border-secondary text-secondary placeholder:text-secondary disabled:text-secondary/20 h-10 bg-transparent'
                type='email'
                placeholder={t('common:newsletter.email_placeholder')}
                {...register('email', {
                  required: t('common:validation.required'),
                  pattern: {
                    value: emailPattern,
                    message: t('common:validation.invalid_email'),
                  },
                })}
                onChange={(value) => {
                  setValue('email', value);
                }}
                error={errors.email?.message}
                value={email}
                disabled={success}
                hideErrorSpace
              />
              {success && (
                <p className='text-success pb-2 text-sm'>{t('common:newsletter.success')}</p>
              )}
            </fieldset>

            <MainButton
              type='submit'
              className='h-10 w-full px-6 ring-offset-gray-100 disabled:bg-black/10 sm:w-auto'
              loading={isPending}
              disabled={success}
              theme='secondary'
            >
              {t('common:newsletter.subscribe')}
            </MainButton>
          </form>
        </div>
        <div className='bg-secondary-light relative hidden w-24 -rotate-[25deg] scale-[2] md:block lg:w-12 ltr:left-6 rtl:right-6 rtl:rotate-[25deg]'></div>
        <div className='bg-secondary-light hidden grow items-center justify-center px-4 py-10 md:flex md:py-4 ltr:rounded-r-md rtl:rounded-l-md'>
          <div className='relative h-24 w-24'>
            <Image src='/icons/logo.png' fill sizes='200px' alt='' />
          </div>
        </div>
      </div>
    </Reveal>
  );
};

export default NewsLetterSection;
