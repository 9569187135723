import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from '@/api/axios';
import { Exception } from '@/src/errors/exception';
import { EventListing } from './types';

type Props = {
  locale?: string;
  seasonSlug?: string;
  page?: number;
  perPage?: number;
};

export const getEvents = async (params?: Props) => {
  try {
    const base = `/organizations/${process.env.NEXT_PUBLIC_ORGANIZATION_SLUG}/events`;

    const data = await axiosInstance.get<ApiResponse<Paginated<EventListing[]>>>(base, {
      params: {
        visible_in: process.env.NEXT_PUBLIC_APP_SOURCE,
        lang: params?.locale || 'en',
        page: params?.page || '',
        perPage: params?.perPage || '',
      },
    });

    return data.data;
  } catch (error) {
    throw new Exception({ error, name: 'getEventsException' });
  }
};

export const useGetEventsList = (props?: Props) => {
  return useQuery({
    queryKey: ['eventsListing', props],
    queryFn: () => getEvents(props),
  });
};
