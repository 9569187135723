import { captureException } from './logger';
import { TrackContext } from './types';

type ExceptionProps = {
  error: Error | unknown;
  context?: TrackContext;
  name?: string;
};

export class Exception extends Error {
  status?: number;
  error?: { [key: string]: string | string[] };
  eventId: string;

  constructor({ error, context, name = 'UnnamedException' }: ExceptionProps) {
    super((error as Error)?.message || 'Something went wrong');
    this.name = name;
    this.error = context as typeof this.error;
    this.status = context?.status || 404;
    this.eventId = '';

    this.captureError();
  }

  async captureError() {
    this.eventId = (await captureException(this, this.error)) || '';
  }
}
