import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Exception } from '@/src/errors/exception';
import { axiosInstance } from '../axios';

type Props = { email: string };

const subscribe = async ({ email }: Props) => {
  try {
    const { data } = await axiosInstance.post('/newsletter-subscribe', {
      email,
    });

    if (data.status?.toLowerCase() !== 'success') {
      const msg = Object.values(data?.error || {})[0] || data?.error;
      throw new Error(msg);
    }

    return data.data;
  } catch (error) {
    const isExists = (error as AxiosError)?.response?.status === 422;
    if (isExists) {
      return true;
    }

    throw new Exception({
      error,
      name: 'subscribeException',
    });
  }
};

export const useSubscribe = () => {
  return useMutation({
    mutationFn: subscribe,
  });
};
