import { useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { matchDatesSplitted } from '@/util/date';
import { EventListing } from '@/api/events/types';
import { DEFAULT_FLAG } from '@/src/constants/defaultFlag';
import { Team } from '@/api/teams/types';
import usePixels from '@/hooks/usePixels';
import ImageWithFallback from '../image/ImageWithFallback';
import MainButton from '../buttons/MainButton';

type Props = {
  event: EventListing & {
    home_team?: Pick<Team, '_id' | 'name' | 'logo'>;
    away_team?: Pick<Team, '_id' | 'name' | 'logo'>;
  };
} & React.HTMLAttributes<HTMLElement>;

const MatchListItem = ({ event, ...props }: Props) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { generateLeadEvent } = usePixels();

  const matchDates = useMemo(() => {
    return matchDatesSplitted(event.start_date_time, locale);
  }, [event.start_date_time, locale]);

  return (
    <MainButton
      theme='transparent'
      queueProtected
      as={Link}
      href={`/${locale}/match/${event.slug}`}
      className='ring-primary flex w-full grow flex-col items-center gap-6 px-4 py-4 text-white focus:ring-1 md:flex-row'
      onClick={() => {
        if (event.price) {
          generateLeadEvent({
            event_start_price: event.price,
            currency: 'SAR',
          });
        }
      }}
      {...props}
    >
      <div className='flex w-full grow justify-center gap-4 md:items-center'>
        <div className='hidden w-20 truncate rounded-md bg-white/10 p-2 text-center leading-none md:block'>
          <p className='text-sm'>{matchDates.month}</p>
          <p className='-my-1 text-3xl font-bold'>{matchDates.day}</p>
          <p className='text-sm'>{matchDates.year}</p>
        </div>

        <div className='flex w-full flex-col items-center gap-6 md:flex-row'>
          <div className='flex items-center gap-5'>
            <figure className='relative h-20 w-20 shrink-0 md:h-16 md:w-16 lg:h-20 lg:w-20'>
              <ImageWithFallback
                src={event?.home_team?.logo}
                className='object-cover'
                fill
                sizes='80px'
                fallbackSrc={DEFAULT_FLAG}
                alt={event?.home_team?.name || ''}
              />
            </figure>
            <p className='hidden text-sm text-gray-200 md:block'>VS</p>
            <div className='space-y-1 text-center md:hidden'>
              <p className='rounded-md bg-white/5 px-2 py-1'>{`${matchDates.day} ${matchDates.month} ${matchDates.year}`}</p>
              <p className='font-bold'>{matchDates.time}</p>
            </div>

            <figure className='relative h-20 w-20 shrink-0 md:h-16 md:w-16 lg:h-20 lg:w-20'>
              <ImageWithFallback
                src={event?.away_team?.logo}
                className='object-cover'
                fill
                sizes='80px'
                fallbackSrc={DEFAULT_FLAG}
                alt={event?.away_team?.name || ''}
              />
            </figure>
          </div>

          <div className='space-y-1 text-start'>
            <p className='text-2xl md:text-xl lg:text-2xl'>
              <strong>{event?.home_team?.name || 'N/A'}</strong>
              <span className='text-xl'>{' VS '}</span>
              <strong>{event?.away_team?.name || 'N/A'}</strong>
            </p>
            <p className='hidden font-bold md:block'>{matchDates.time}</p>
          </div>
        </div>
      </div>
      <MainButton
        className='w-full whitespace-nowrap text-xl font-bold md:max-w-[140px] md:!py-1 md:text-base'
        arrow
        tabIndex={-1}
      >
        {t('event:buy_tickets')}
      </MainButton>
    </MainButton>
  );
};

export default MatchListItem;
